<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="container mt-4">
        <div class="flex">
          <router-link to="/main" class="mx-4 text-blue-500">
            <i class="fas fa-chevron-left font-semibold"></i>
            {{ $t("back") }}
          </router-link>
        </div>
        <form class="m-4 md:mx-24" @submit="onSubmit">
          <div class="flex mb-4">
            <label class="w-28 font-semibold">{{ $t("notes") }}</label>
            <t-textarea
              v-model="text"
              class="flex-grow"
              rows="15"
              required
            ></t-textarea>
          </div>
          <div class="ml-28">
            <t-button class="flex-grow" variant="primary" :disabled="loading">{{
              $t("edit_save")
            }}</t-button>
          </div>
        </form>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import { mapActions, mapGetters } from "vuex";
import { get } from "lodash";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    text: null,
  }),
  mounted() {
    this.text = get(this.inputNote, "text");
  },
  methods: {
    ...mapActions({
      upsertInputNote: actions.INPUTNOTE_UPSERT_ACTION,
    }),
    async onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      try {
        await this.upsertInputNote(this.text);
        this.$router.push('/new-rooster');
      } catch (error) {
        console.error("DEBUG: onSubmit", error);
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters({
      inputNote: getters.INPUTNOTE_GETTER,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
